import React from "react"

import SEO from "../components/seo"
import SupportPage from "../components/layout/SupportPage"
import PageHeader from "../components/layout/PageHeader"
import DownloadButton from "../components/buttons/DownloadButton"

const WarrantyPage = () => (
  <>
  	<SEO page="warranty" title="3 Year Warranty" />	
    <SupportPage page="resources" title="Warranty">
		<PageHeader title="SignMonkey Warranty" />

		<p>
			SignMonkey.com warrants that the EZLit Letters and EZLit Logo Shapes, Raceway Letters and Logos, Lit Cabinets and Lit Shape 
			Signs will be free from defects in materials and workmanship for a period of 3 years from the date of shipment. 
			Should there be a malfunction covered under this warranty, the buyer must notify SignMonkey.com prior to taking any 
			action. To proceed with any work or repairs on signs under warranty without notifying SignMonkey.com automatically voids 
			any warranty.
		</p>
		<p>
			If the product proves defective during the warranty period, SignMonkey.com, at its option, will:
		</p>
		<p>
			(1) Repair the product, at no charge for parts or support, by means of telephone/email support<br />
			(2) Replace the product with an equivalent product which may be new or refurbished
		</p>
		<p>
			SignMonkey.com tests and inspects all signs for proper operation prior to shipment. In addition, all signs are shipped in packaging that meets or exceeds industry standards for common carrier shipment. Therefore, it is the responsibility of the party receiving the shipment to inspect the packaging for any damage prior to accepting shipment. If packaging is damaged – inspect all contents for damages before signing. In the event that shipping damage is determined, it is the responsibility of the customer receiving the shipment to file a claim with the carrier and notify by email support@signmonkey. Any damages incurred between shipment from our plant and final destination will not be the responsibility of SignMonkey.com, but will fall on the carrier.
		</p>
		<p>
			This warranty does not apply to damages resulting from acts of God, accidental negligence, vandalism or misuse. These warranties shall not apply to any defect, failure or damage caused by improper use, installation or care. SignMonkey.com shall not be obligated under this warranty.
		</p>
		<p>
			The above warranties are given by SignMonkey.com with respect to this product and its related items in lieu of any other warranties, express or implied. It is the responsibility of SignMonkey.com to repair defective products and related items as the sole remedy provided to the customer for honor of this warranty.
		</p>

		<br />
		
		<DownloadButton 
			url={"2020/warranty.jpg"}
			imageSrc="SignTypes/warranty-thumb-93x74-93x74.jpg"
			label="SignMonkey 3 Year Warranty"
		/>
    </SupportPage>
  </>
)

export default WarrantyPage